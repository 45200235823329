import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";

function ErrorPage() {
    return (
        <section >
            <Navbar variant="customLogin">
                <Container>
                    <NavLink
                        to="/"
                        className="text-white d-flex gap-2 align-items-center">
                        <img
                            alt="logo yayasan"
                            src="/images/logo.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        <p>PPDB Asy-Syukriyyah Islamic School Dashboard</p>
                    </NavLink>
                </Container>
            </Navbar>
            <span className="circle1" />
            <span className="circle2" />
            <Container>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                            <div style={{ display: 'block' }}>
                                <h1 className="mb-3" style={{ color: "#00923F" }}>Halaman tidak ada</h1>
                                <Link
                                    to="/"
                                    style={{ textDecoration: "none", color: "#00923F" }}
                                    className="mt-1"
                                >
                                    <BsIcons.BsArrowLeftShort />
                                    Kembali ke halaman utama
                                </Link>
                            </div>
                        </div></Col>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                            <img src="/images/404.png" alt="404" width={500} />
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}
export default ErrorPage;